<template>
  <v-container fluid class="login-bkg height-100">
    <v-overlay v-model="loading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-overlay>
    <v-row :class="$vuetify.breakpoint.xs ? 'pt-75' : 'pt-150'">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="10" md="7" lg="5" xl="4">
        <v-card tile>
          <v-system-bar
            height="60"
            color="primary"
            class="white--text elevation-5"
          >
            <v-card-title>
              <h3>Resumation Account Creation</h3>
            </v-card-title>
          </v-system-bar>
          <v-system-bar height="5" color="accent" class=""></v-system-bar>
          <v-card-text>
            <span class="text-body-1 text--primary"
              >Create a new Resumation Account</span
            >
          </v-card-text>
          <v-divider />
          <v-card-text>
            <div class="pa-4">
              <v-form ref="form" v-model="valid" @submit.prevent="register()">
                <v-text-field
                  v-model="name"
                  label="Name"
                  dense
                  class="pb-1"
                  :rules="nameRules"
                  type="text"
                  @click:append="showPwd = !showPwd"
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  label="Email Address"
                  :rules="emailRules"
                  type="text"
                  dense
                  class="pb-1"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  dense
                  class="pb-1"
                  :rules="pwdRules"
                  :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPwd ? 'text' : 'password'"
                  @click:append="showPwd = !showPwd"
                ></v-text-field>
                <div>
                  Please read and agree to use the application.
                  <v-checkbox v-model="termsAgree" :rules="termsRules">
                    <template v-slot:label>
                      <div>
                        I have read and agree to the
                        <a target="_blank" href="/TermsOfUse" @click.stop>
                          Terms Of Use
                        </a>
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox v-model="privacyAgree" :rules="privacyRules">
                    <template v-slot:label>
                      <div>
                        I have read and agree to the
                        <a target="_blank" href="/PrivacyNotice" @click.stop>
                          Privacy Notice
                        </a>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
                <div class="d-flex justify-center" v-if="errorText !== ''">
                  <p class="error--text text-body-1 font-weight-medium">
                    {{ errorText }}
                  </p>
                </div>
                <div class="d-flex justify-center align-center pt-4">
                  <v-btn
                    type="submit"
                    :disabled="loading"
                    color="primary"
                    class="px-4"
                  >
                    Create Account
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-card-text>
          <div class="pb-4">
            <v-btn text @click="navTo('/Login')">
              <v-icon>mdi-arrow-left</v-icon>
              Login
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import * as login from "../../api/LoginApi";

// @ is an alias to /src
export default {
  name: "CreateAccount",
  data: () => ({
    valid: true,
    email: "",
    password: "",
    name: "",
    showPwd: false,
    termsAgree: false,
    privacyAgree: false,
    termsRules: [
      (v) =>
        !!v ||
        "You must agree to the Terms of Use in order to use the application.",
    ],
    privacyRules: [
      (v) =>
        !!v ||
        "You must agree to the Privacy Notice in order to use the application.",
    ],
    pwdRules: [
      (v) => !!v || "Required.",
      (v) => v.length >= 12 || "Min 12 characters",
      (v) =>
        /.*[A-Z].*/.test(v) || "Must contain at least one uppercase letter",
      (v) =>
        /.*[a-z].*/.test(v) || "Must contain at least one lowercase letter",
      (v) => /.*[0-9].*/.test(v) || "Must contain at least one number",
      (v) =>
        /.*[!@#$%^&*()\-_+=].*/.test(v) ||
        "Must contain at least one special character",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..{2,}/.test(v) || "E-mail must be valid",
    ],
    nameRules: [(v) => !!v || "Name is required"],
    loading: false,
    errorText: "",
  }),
  async mounted() {},
  methods: {
    ...mapActions("auth", ["logUserIn"]),
    hardNavTo(href) {
      window.location.href = href;
    },
    navTo(href) {
      this.$router.push({ path: href });
    },
    async checkLoggedIn() {
      try {
        let isLoggedIn = await this.logUserIn();
        if (isLoggedIn) {
          this.loading = false;
          this.navTo("/Resume");
        }
      } finally {
        this.loading = false;
      }
    },
    async register() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      try {
        this.errorText = "";
        this.loading = true;

        const createResponse = await login.CreateNewUser(
          this.email,
          this.password,
          this.name
        );

        if (!createResponse.success) {
          this.errorText = createResponse.displayText;
        } else {
          this.checkLoggedIn();
        }
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.pt-150 {
  padding-top: 150px;
}

.pt-75 {
  padding-top: 75px;
}
</style>
